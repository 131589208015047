import { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import PageContainerWrap from "components/PageContainerWrap/PageContainerWrap";
import ReactPlayer from "react-player";
import { isIOS } from 'react-device-detect';
import { baseUrl } from "utils/config";

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



const useStyles = makeStyles((theme) => ({
  aboutUsVideoPageWrap: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "grid",
    },
    width: "80%",
    padding: "0 10%",
  },
}))

const AboutUs = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView();
  }, [])

  return (
    <PageContainerWrap>
      <div className={classes.aboutUsVideoPageWrap}>
        { isIOS ? ( 
          <ReactPlayer 
            width="100%" 
            height="auto" 
            url={baseUrl + "/video/f8bf915e-5c02-45c2-8d23-e897b9fa347d/MP4/f8bf915e-5c02-45c2-8d23-e897b9fa347d.mp4"}
            controls /> )
        : ( 
          <ReactPlayer 
            width="100%" 
            height="auto" 
            controls
            url= {baseUrl + "/video/f8bf915e-5c02-45c2-8d23-e897b9fa347d/DASH/f8bf915e-5c02-45c2-8d23-e897b9fa347d.mpd"}
            playsinline
            muted
            config={{
              file: {
                forceHLS: true,
              }
            }}/>
        ) }
      </div>
    </PageContainerWrap>
    )
};

export default AboutUs;
