import { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import PageContainerWrap from "components/PageContainerWrap/PageContainerWrap";
import ContentProfilePage from "components/ProfilePage/ContentProfilePage/ContentProfilePage";
import UserInfoBar from "components/UserInfoBar/UserInfoBar";
import Loading from "Shared/Loading/Loading";

import api from "utils/api";
import useRouter from "utils/hooks/useRouter";
import { setUserInfo, setProfileUserInfo } from "features/authentication/authenticationSlice";

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  }
}))


const ProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  const userIdentity = useSelector(state => state?.authentication?.identityId);

  const getUserProfile = useCallback(async () => {
    setIsLoading(true);

    const queryUser = router.query?.userId;

    if (queryUser && queryUser === userIdentity){
      setIsOwnProfile(true);
    }

    try {
      const response = await api.get(`/users/${queryUser}`);
      dispatch(setProfileUserInfo(response));

      dispatch(setUserInfo(response));
      if (isOwnProfile) {
        dispatch(setUserInfo(response));
      }

      if (queryUser && queryUser === userIdentity){
        setIsOwnProfile(true);
        dispatch(setUserInfo(response));
      }
    } catch (e) {
      console.log("e", e);
    }
    setIsLoading(false);

  }, [router.query?.userId, dispatch, userIdentity, isOwnProfile])

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView();
  }, [])

  return (
    <PageContainerWrap>
      {isLoading && <Loading />}
      <div className={classes.root}>
      {isOwnProfile ? (
        <UserInfoBar />
      ) : (null)}
        <ContentProfilePage isOwner={isOwnProfile}/>
       </div>
    </PageContainerWrap>
  )
};

export default ProfilePage;
