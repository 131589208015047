import React, { useState, useEffect } from "react";
import { object, func } from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { mixin } from "utils/styles";

const useStyles = makeStyles(() => ({
  videoInfoWrap: {
    flex: "0 0 40%",
    marginLeft: 32,
  },
  videoEditIcon: {
    float: "left",
  },
  videoTitle: {
    fontWeight: "bold",
  },
  videoDescription: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: "normal",
  },
  videoCommonInfo: {
    marginTop: 16,
  },
  lineInfo: {
    display: "flex",
    fontSize: 16,
    "& span": {
      marginLeft: 16,
    },
    "&:not(:first-child)": {
      marginTop: 16
    }
  },
  lineInfoLabel: {
    minWidth: 100,
    fontWeight: "bold",
  },
  videoInfoHeader: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const VideoInfo = ({
  video, onSwitchEditVideoScreen
}) => {
  const classes = useStyles();
  const identityId = useSelector(state => state.authentication.identityId);
  const [videoInfo, setVideoInfo] = useState({});

  useEffect(() => {
    setVideoInfo(video)
  }, [video]);

  return (
    <div className={classes.videoInfoWrap}>
      <div className={classes.videoInfoHeader}>
        <Typography
          className={classes.videoTitle}
          color="primary"
          variant="h5"
          component="h5"
        >
          {videoInfo.video_title}
        </Typography>
        {(identityId === videoInfo.video_user_id) ? (
          <IconButton className={classes.videoEditIcon}
            color="secondary"
            onClick={onSwitchEditVideoScreen}
          >
            <EditIcon />
          </IconButton>
        ) : (null)}
      </div>
      <div className={classes.videoDescription}>
        {videoInfo.video_description}
      </div>
      <div className={classes.videoCommonInfo}>
        {(identityId === videoInfo.video_user_id) ? (
          <div className={classes.lineInfo}>
            <div className={classes.lineInfoLabel}>Video Status:</div>
            <span>{videoInfo.status}</span>
          </div>
        ) : (null)}

        <div className={classes.lineInfo}>
          <div className={classes.lineInfoLabel}>Store Name:</div>
          <span>{videoInfo.store_name}</span>
        </div>
        <div className={classes.lineInfo}>
          <div className={classes.lineInfoLabel}>Store URL:</div>
          <span style={mixin.linkStyle}>
            <a target="_blank" rel="noreferrer" href={videoInfo.store_url}>{videoInfo.store_url}</a>
          </span>
        </div>
      </div>
    </div>
  );
};

VideoInfo.propTypes = {
  video: object,
  onSwitchEditVideoScreen: func
};

VideoInfo.defaultProps = {
  video: {},
  onSwitchEditVideoScreen: () => {}
};

export default VideoInfo;
