import { useState, Fragment } from "react";


import {
  makeStyles,
  Typography,
  TextField,
  FormGroup,
  IconButton,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import ModalComponent from "components/ModalComponent/ModalComponent";
import Loading from "Shared/Loading/Loading";
import isEmpty from "lodash/isEmpty";

import { color } from "utils/styles";
import { renderErrors } from "utils/function";
import api from "utils/api";

const useStyles = makeStyles((theme) => ({
  createStoreModal: {
    minWidth: "500px",
    [theme.breakpoints.down('md')]: {
      minWidth: "300px",
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: "250px",
    },
  },
  modalTitleBlock: {
    display: "flex",
    marginBottom: 20,
  },
  modalTitle: {
    width: "100%",
  },
  closeButton: {
    color: color.primary,
    padding: "0px 12px",
  },
  formGroup: {
    "&:not(:first-child)": {
      marginTop: 32,
    },
  },
  buttonGroup: {
    marginTop: 32,
  },
  submitButton: {
    borderRightColor: "unset !important",
  },
  resetButton: {
    marginLeft: "16px !important",
    backgroundColor: color.errors,
    color: color.white,

    "&:hover": {
      color: color.black,
    },
  },
}));

const CreateStoreModal = (props) => {
  const { setStoreInfo, user_id, handleClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { handleSubmit, control, formState: { errors }, clearErrors } = useForm();
  const [showCloseButton, setShowCloseButton] = useState(true);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setShowCloseButton(false);
    try {
      const response = await api.post(`/users/${user_id}/stores`, data);
      await setStoreInfo(response);
    } catch (e) {
      console.log("e", e);
    }
    setIsLoading(false);
    setShowCloseButton(true);
  };

  const onCloseModal = () => {
    Object.keys(errors).forEach(key => {
      clearErrors(key, "")
    });
    handleClose();
  }

  return (
    <Fragment>
      {isLoading && <Loading />}
      <ModalComponent {...props} handleClose={onCloseModal}>
      <div className={classes.createStoreModal}>
        <div className={classes.modalTitleBlock}>
          <Typography
            className={classes.modalTitle}
            variant="h6"
            component="h6"
            gutterBottom
          >
            Create Your Store
          </Typography>
          { showCloseButton ? (<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton> ): null}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className={classes.formGroup}>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <TextField
                  autoComplete="off"
                  autoFocus
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  label="Store Name"
                  defaultValue=""
                  name={name}
                  innerRef={ref}
                  error={!isEmpty(errors?.store_name)}
                  helperText={renderErrors(errors?.store_name?.type)}
                />
              )}
              rules={{
                required: true
              }}
              control={control}
              name="store_name"
            />
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <TextField
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  label="Store description"
                  defaultValue=""
                  name={name}
                  innerRef={ref}
                  error={!isEmpty(errors?.store_description)}
                  helperText={renderErrors(errors?.store_description?.type)}
                />
              )}
              control={control}
              rules={{
                required: true
              }}
              name="store_description"
            />
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <TextField
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  label="Store URL"
                  defaultValue=""
                  name={name}
                  innerRef={ref}
                  error={!isEmpty(errors?.store_url)}
                  helperText={renderErrors(errors?.store_url?.type)}
                />
              )}
              control={control}
              rules={{
                required: true
              }}
              name="store_url"
            />
          </FormGroup>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              variant="contained"
              className={classes.submitButton}
              color="primary"
              type="submit"
            >
              Submit
            </Button>
            <Button className={classes.resetButton}>Reset</Button>
          </ButtonGroup>
        </form>
      </div>
    </ModalComponent>
    </Fragment>
  );
};

export default CreateStoreModal;
