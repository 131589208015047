import { Fragment, useState, useEffect } from "react";
import { object, func } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, TextField, FormControl, ButtonGroup, Button, Switch } from "@material-ui/core";
import { mixin } from "utils/styles";
import { useSelector } from "react-redux";

import SnackbarMessage from "Shared/Snackbar/Snackbar";
import Loading from "Shared/Loading/Loading";
import api from "utils/api";

const useStyles = makeStyles((theme) => ({
  formSubmit: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    width: "95%",
    flex: "0 0 40%",
  },
  formControl: {
    margin: theme.spacing(2)
  },
  formGroupSwitch: {
    margin: theme.spacing(2),
    display: "block",
  },
  resetButton: {
    marginLeft: "12px !important"
  }
}));

const VideoEdit = ({
  updatedField, onEditVideo, setVideo
}) => {
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: updatedField
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: ""
  });

  const userInfo = useSelector(state => state?.authentication?.userInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const classes = useStyles();

  const onToggle = () => {
    setIsLive(isLive => !isLive);
  }

  const onSubmit = async data => {
    setIsLoading(true);
    data.status = isLive ? "live" : "draft";
    const form = {
      user_id: userInfo?.user_id,
      ...data,
    }
    const response = await api.put(`/videos/${updatedField?.video_id}`, form);

    await setOpenSnackbar({
      open: true,
      message: "Update video success!",
      type: "success"
    });
    await setVideo(response);
    await onEditVideo();
    setIsLoading(false);
  }
  const handleClose = () => {
    setOpenSnackbar({
      open: false,
      message: "",
      type: ""
    })
  }

  useEffect(() => {
    const statusIsLive = updatedField.status === "live" ? true : false;
    setIsLive(statusIsLive);
  }, [updatedField]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formSubmit}>
        <FormControl className={classes.formControl}>
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
            }) => (
              <TextField
                autoComplete="off"
                autoFocus
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                label="Product title"
                defaultValue={value}
                name={name}
                innerRef={ref}
              />
            )}
            control={control}
            name="video_title"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
            }) => (
              <TextField
                autoComplete="off"
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                label="Product description"
                defaultValue={value}
                name={name}
                innerRef={ref}
              />
            )}
            control={control}
            name="video_description"
          />
        </FormControl>
        <FormControl className={classes.formGroupSwitch}>
          <span> draft</span>
          <Controller
            render={({
              field: { onBlur, name, ref },
            }) => (
              <Switch
                checked={isLive}
                onChange={onToggle}
                onBlur={onBlur}
                variant="outlined"
                name={name}
                innerRef={ref}
              />
            )}
            control={control}
            name="status"
          />
          <span> live</span>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
            }) => (
              <TextField
                autoComplete="off"
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                label="Product Link"
                defaultValue={value}
                name={name}
                innerRef={ref}
              />
            )}
            control={control}
            name="link"
          />
        </FormControl>
        <ButtonGroup className={classes.buttonGroup}>
          <Button
            variant="contained"
            className={classes.submitButton}
            color="primary"
            type="submit"
          >
            Submit
          </Button>
          <Button
            className={classes.resetButton}
            style={mixin.resetButton}
            onClick={onEditVideo}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </form>
    <SnackbarMessage
      open={openSnackbar.open}
      type={openSnackbar.type}
      message={openSnackbar.message}
      setOpen={handleClose}
    />
    {isLoading && <Loading />}
  </Fragment>
  )
};

VideoEdit.propTypes = {
  updatedField: object,
  onEditVideo: func,
  setVideo: func
};

VideoEdit.defaultProps = {
  updatedField: {},
  onEditVideo: () => {},
  setVideo: () => {}
};

export default VideoEdit;
