import { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  FormControl,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { mixin } from "utils/styles";

import PageContainerWrap from "components/PageContainerWrap/PageContainerWrap";
import Loading from "Shared/Loading/Loading";
import api from "utils/api";
import { renderErrors } from "utils/function";

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



const useStyles = makeStyles((theme) => ({
  formSubmit: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    width: "50%",
  },
  formControl: {
    margin: theme.spacing(2),
  },
  formGroupSwitch: {
    margin: theme.spacing(2),
    display: "block",
  },
  resetButton: {
    marginLeft: "12px !important",
  },
  contactTitle: {
    margin: "20px 16px",
  }
}));

const ContactUs = () => {

  const { handleSubmit, control, reset, setError, formState: { errors }, clearErrors } = useForm();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: ""
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await api.post(
        `/contactus`,
        data
      );
      if (response.status === 200) {
        await setOpenSnackbar({
          open: true,
          message: "Message sent successfully. You will hear back from us shortly.",
          type: "success",
        });
        reset();
      } else {
        await setOpenSnackbar({
          open: true,
          message: "Message failed. Please try again.",
          type: "error",
        });
      }
    } catch (e) {
      setOpenSnackbar({
        open: true,
        message: "Message failed to send!",
        type: "error",
      });
      reset();
    }
    setIsLoading(false);
  };

  const onReset = () => {
    reset();
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView();
  }, [])

  return (
    <PageContainerWrap>
    <form className={classes.formSubmit} onSubmit={handleSubmit(onSubmit)}>
    <Typography
      className={classes.contactTitle}
      variant="h6"
      component="h6"
      gutterBottom
    >
      We will get back to you shortly.
    </Typography>
      <FormControl className={classes.formControl}>
        <Controller
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              autoComplete="off"
              autoFocus
              onChange={onChange}
              onBlur={onBlur}
              variant="outlined"
              label="Your Name"
              defaultValue={value}
              name={name}
              innerRef={ref}
              error={!isEmpty(errors?.store_name)}
              helperText={renderErrors(errors?.store_name?.type)}
            />
          )}
          rules={{
            required: true,
          }}
          control={control}
          name="contact_name"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Controller
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              autoComplete="off"
              onChange={onChange}
              onBlur={onBlur}
              variant="outlined"
              label="Your Email"
              defaultValue={value}
              name={name}
              innerRef={ref}
              error={!isEmpty(errors?.store_url)}
              helperText={renderErrors(errors?.store_url?.type)}
            />
          )}
          rules={{
            required: true,
          }}
          control={control}
          name="contact_email"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Controller
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              autoComplete="off"
              onChange={onChange}
              onBlur={onBlur}
              variant="outlined"
              label="Message"
              defaultValue={value}
              name={name}
              innerRef={ref}
              error={!isEmpty(errors?.store_description)}
              helperText={renderErrors(errors?.store_description?.type)}
              multiline
              rows={10}
              rowsMax={20}
            />
          )}
          rules={{
            required: true,
          }}
          control={control}
          name="contact_msg"
        />
      </FormControl>
      <ButtonGroup className={classes.buttonGroup}>
        <Button
          variant="contained"
          className={classes.submitButton}
          color="primary"
          type="submit"
        >
          Submit
        </Button>
        <Button
          className={classes.resetButton}
          style={mixin.resetButton}
          onClick={onReset}
        >
          Reset
        </Button>
      </ButtonGroup>
    </form>
    {isLoading && <Loading />}
    </PageContainerWrap>
    )
};

export default ContactUs;
