import { useState, useMemo, useEffect, createContext } from "react";
import { makeStyles, AppBar, Tabs, Tab } from "@material-ui/core";

import TabPanel from "components/TabPanel/TabPanel";
import ListVideos from "components/ProfilePage/ListVideos/ListVideos";
import UserStore from "components/ProfilePage/UserStore/UserStore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "24px",
    width: "100%",
    [theme.breakpoints.down('md')]: {
      marginLeft: "10px",
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
    },
  }
}));

export const TabContext = createContext({
  tab: 1,
  setValueTab: () => {}
})

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentProfilePage = ({isOwner}) => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const [isProfileOwner, setIsProfileOwner] = useState(false);


  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  }

  const tabContextValue = { value: valueTab, setValueTab };

  const queryStringVideos = useMemo(() => {
    let query = "";
    if (valueTab === 0) {
      query = "";
    } else {
      query = "&like=true"
    }
    return query;
  }, [valueTab]);

  useEffect(() => {
    setIsProfileOwner(isOwner);
  }, [isOwner]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="profile-page-content-tab">
          <Tab label="Video" {...a11yProps(0)}  />
          <Tab label="Favorites" {...a11yProps(1)}  />
          <Tab label="Store" {...a11yProps(2)}  />
        </Tabs>
      </AppBar>
      <TabContext.Provider value={tabContextValue}>
        <TabPanel
          value={valueTab}
          index={0}
        >
          <ListVideos
            setValueTab={setValueTab}
            queryStringVideos={queryStringVideos}
            isOwner={isOwner}
            valueTab={0}
          />
        </TabPanel>
        <TabPanel
          value={valueTab}
          index={1}
        >
          <ListVideos
            setValueTab={setValueTab}
            queryStringVideos={queryStringVideos}
            isOwner={isProfileOwner}
            valueTab={1}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <UserStore
            isOwner={isProfileOwner}
            setValueTab={setValueTab}
          />
        </TabPanel>
      </TabContext.Provider>
    </div>
  )
};

export default ContentProfilePage;
