import React, { useState } from "react";
import { string, bool, object } from "prop-types";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { isIOS } from 'react-device-detect';
import { makeStyles } from "@material-ui/core";

import IconVideoKit from "Shared/VideoPlayer/IconVideoKit/IconVideoKit";
import { baseUrl } from "utils/config";

const useStyles = makeStyles(() => ({
  singleVideoDetailPageWrap: {
    flex: "0 0 50%",
    position: "relative",
  },
  iconVideoKitWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50px",
    position: "absolute",
    bottom: 25,
    right: 8,
    maxHeight: "50%",
  },
}))

const VideoPlayer = ({
  url, height, playing, width, videoInfo
}) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [video, setVideo] = useState();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

  const classes = useStyles();

  const onMouseEnter = (e) => {
    if (e) {
      setIsMouseEnter(true);
    }
  };


  const onMouseLeave = () => {
    if (isMouseEnter) {
      setIsMouseEnter(false);
    }
  };

  const videoSrc = videoInfo?.video_id ? baseUrl + "/video/" + videoInfo?.video_id +"/DASH/" + videoInfo?.video_id + ".mpd" : null;
  const videoSrcIos = videoInfo?.video_id ? baseUrl + "/video/" + videoInfo?.video_id +"/MP4/" + videoInfo?.video_id + ".mp4" : null;

  return (
    <div
      className={classes.singleVideoDetailPageWrap}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        marginLeft: videoInfo?.orientation === "h" ? "0" : "20%",
      }}
    > {  videoSrc ? ( videoInfo?.orientation === "h" ? ( isIOS ? (
        <ReactPlayer width="100%" height="auto"
          url={videoSrcIos}
          controls
          />
          ) : (
              <ReactPlayer width="100%" height="auto"
                url={videoSrc}
                controls
                muted
                playing
                playsinline
                loop
                config={{
                  file: {
                    forceHLS: true,
                  }
                }}
                />
          )) : ( isIOS ? (
        <ReactPlayer width="70%" height="auto"
          url={videoSrcIos}
          controls
          />
          ) : (
            <ReactPlayer width="70%" height="auto"
              url={videoSrc}
              controls
              playing
              muted
              playsinline
              loop
              config={{
                file: {
                  forceHLS: true,
                }
              }}
              />
          ))) : (
            null
          )}

        <div
          className={classes.iconVideoKitWrap}
          style={{
            right: isMouseEnter ? "8px" : "8px",
            transition: "all .2s ease-in",
          }}
        >
          {videoInfo?.status === "live" && (
            <IconVideoKit
              videoInfo={videoInfo}
            />
          )}
        </div>
    </div>
  )
};

VideoPlayer.propTypes = {
  url: string,
  height: string,
  playing: bool,
  width: string,
  videoInfo: object
};

VideoPlayer.defaultProps = {
  url: "",
  height: "100%",
  width: "100%",
  playing: false,
  videoInfo: {}
}
export default VideoPlayer;
