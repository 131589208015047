import { useState, useEffect, useCallback, Fragment } from "react";
import axios from "axios";
import { makeStyles, Avatar, Button, Typography, ButtonGroup, IconButton } from "@material-ui/core";
import { useSelector } from "react-redux"
import { bool, func } from "prop-types";
import Alert from "@material-ui/lab/Alert";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Tooltip from "@material-ui/core/Tooltip";
import isEmpty from "lodash/isEmpty";

import CreateStoreModal from "components/CreateStoreModal/CreateStoreModal";
import UpdateStoreForm from "./UpdateStoreForm";
import UploadNewVideoModal from "components/UploadNewVideoModal/UploadNewVideoModal";
import Loading from "Shared/Loading/Loading";
import SnackbarMessage from "Shared/Snackbar/Snackbar";

import api from "utils/api";
import { baseUrl } from "utils/config";
import { mixin } from "utils/styles";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "24px",
    width: "100%",
  },
  buttonOpenCreataStoreModal: {
    marginTop: 16
  },
  largeAvatar: {
    position: "relative",
    width: "90%",
    height: "90%",
    margin: "0 auto",
  },
  storeWrap: {
    padding: "30px 20px",
    [theme.breakpoints.down('md')]: {
      padding: "0px",
    },
  },
  storeDetails: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "grid",
    },
  },
  storeAvatar: {
    width: "220px",
    opacity: 1,
    display: "block",
    height: "auto",
    transition: ".5s ease",
    backfaceVisibility: "hidden",
    position: "relative",
    "&:hover img": {
      opacity: 0.3
    },

    "&:hover .middle": {
      opacity: 1,
      transition: ".5s ease",
    },
    [theme.breakpoints.down('md')]: {
      width: "180px",
      margin: "10px auto",
    },
  },
  labelIconUpload: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
    position: "absolute",
    opacity: 0,
    zIndex: 200,
  },
  lineInfo: {
    display: "flex",
    fontSize: 16,
    alignItems: "center",
    "& span": {
      marginLeft: 16,
    },
    "&:not(:first-child)": {
      marginTop: 16
    },
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
  typoGraphy: {
    minWidth: 180
  },
  buttonGroup: {
    marginTop: 24,
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
  editButton: {
    marginLeft: "16px! important"
  }
}))

const UserStore = ({ isOwner, setValueTab }) => {
  const classes = useStyles();
  const [isCreateStore, setIsCreateStore] = useState(false);
  const [storeInfo, setStoreInfo] = useState({});
  const [isEditStore, setIsEditStore] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalUploadVideo, setIsOpenModalUploadVideo] = useState(false);
  const [isProfileOwner, setIsProfileOwner] = useState(false);
  const userInfo = useSelector(state => state.authentication.userInfo);
  const profileUserInfo = useSelector(state => state.authentication.profileUserInfo);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });

  const storeImage = `${baseUrl}/picture/store/${userInfo?.store_id}/large.jpg`;
  const [storeAvatar, setStoreAvatar] = useState(userInfo?.store_id ?  storeImage : `${baseUrl}/picture/store/large.jpg`);

  const onCloseModalCreateStore = () => setIsCreateStore(false);

  const getUserStore = useCallback(async () => {
    if (profileUserInfo?.store_id) {
      setIsLoading(true);
      try {
        const response = await api.get(`/users/${profileUserInfo?.user_id}/stores/${profileUserInfo?.store_id}`);
        setStoreInfo(response);
      } catch (e) {
        console.log("e", e);
      }
      setIsLoading(false);
    } else {
      setIsCreateStore(true);
    }
    setIsLoaded(true);
    setIsLoading(false);
  }, [profileUserInfo])

  useEffect(() => {
    getUserStore();
    setIsProfileOwner(isOwner);
  }, [getUserStore, isOwner]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "-store")
    ReactPixel.pageView();
  }, [])

  const onClickOpenCreateStoreModal = () => {
    setIsCreateStore(true);
  }

  const onEditStore = () => {
    setIsEditStore(isEditStore => !isEditStore);
  };

  const onClickOpenUploadNewVideoModal = () => {
    setIsOpenModalUploadVideo(true);
  }

  const onCloseModalUploadNewVideo = () => {
    setIsOpenModalUploadVideo(false)
  }

  const showUploadResult = (result) => {
    if (result.status === 200) {
      setOpenSnackbar({
        open: true,
        message: "Upload picture success! Picture will appear in your profile page once porcessing is complete.",
        type: "success"
      });
    } else {
      setOpenSnackbar({
        open: true,
        message: "Upload picture failed. Please try again.",
        type: "error"
      });
    }
  }

  const onChangeImageStore = async (e) => {

    const files = e.target.files[0];
    const userId = userInfo?.user_id
    const storeId = storeInfo?.store_id

    if (userId) {
      setIsLoading(true);
      await setOpenSnackbar({
        open: true,
        message: "Uploading picture... Please wait.",
        type: "info"
      });
      try {
        const response = await api.get(`/users/${userId}/stores/${storeId}/picture-signed?file_format=${files.type}`);
        const { signed_url } = response;
        if (signed_url) {
          const responsePresignURL = await axios.put(signed_url, files, {
            headers: {
              "Content-Type": files.type
            }
          })
          await showUploadResult(responsePresignURL);
          setStoreAvatar(`${baseUrl}/picture/store/${userInfo?.store_id}/large.jpg`)
        }
      } catch (e) {
        console.log("e", e);
      }
      setIsLoading(false);
    }
  }

  if (!isLoaded || isLoading) {
    return <Loading />;
  }

  const handleClose = () => {
    setOpenSnackbar({
      open: false,
      message: "",
    });
  };
  

  return (
    <div className={classes.storeWrap}>
      {!isEmpty(storeInfo) ? (
        isEditStore ? (
          <UpdateStoreForm
            userInfo={userInfo}
            onEditStore={onEditStore}
            updatedField={{
              store_id: storeInfo?.store_id,
              store_name: storeInfo?.store_name,
              store_url: storeInfo?.store_url,
              store_description: storeInfo?.store_description
            }}
            setStoreInfo={setStoreInfo}
            setOpenSnackbar={setOpenSnackbar}
          />
        ) : (
          <div className={classes.storeDetails}>
            <div className={classes.storeProfile}>
              <div className={classes.storeAvatar}>
                <Avatar alt="Remy Sharp" src={storeAvatar} className={`${classes.largeAvatar} avatar`} />
                  {isProfileOwner ? (
                    <span className={`${classes.labelIconUpload} middle`}>
                      <input
                        accept="image/*"
                        id="icon-button-file-store-avatar"
                        type="file"
                        style={{ display: "none" }}
                        onChange={onChangeImageStore}
                      />
                      <label htmlFor="icon-button-file-store-avatar">
                        <Tooltip title="Upload store avatar">
                          <IconButton aria-label="upload picture" component="span">
                            <PhotoCameraIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </span>
                  ) : (null)}
              </div>
            </div>
            <div>
            <div className={classes.lineInfo}>
              <Typography className={classes.typoGraphy} variant="h6" component="h6">
                Store Name:
              </Typography>
              <span>
                {storeInfo?.store_name}
              </span>
            </div>
            <div className={classes.lineInfo}>
              <Typography className={classes.typoGraphy} variant="h6" component="h6">
                Store URL:
              </Typography>
              <span>
                <a style={mixin.linkStyle} target="_blank" rel="noreferrer" href={storeInfo?.store_url}>{storeInfo?.store_url}</a>
              </span>
            </div>
            <div className={classes.lineInfo}>
              <Typography className={classes.typoGraphy} variant="h6" component="h6">
                Store Description:
              </Typography>
              <span>
                {storeInfo?.store_description}
              </span>
            </div>
              {isProfileOwner ? (
                <ButtonGroup className={classes.buttonGroup}>
                 <Button
                     variant="contained"
                     onClick={onClickOpenUploadNewVideoModal}
                     color="primary"
                   >
                     Upload new video
                   </Button>
                  <Button
                   onClick={onEditStore}
                   variant="contained"
                   color="secondary"
                   type="button"
                   className={classes.editButton}
                  >
                    Edit
                  </Button>
                </ButtonGroup>
              ) : (null)}
             </div>
          </div>
        )
      ) : (
        <Fragment>
          <Alert severity="info">You dont have store yet. Click button to create!</Alert>
          <div>
            <Button className={classes.buttonOpenCreataStoreModal} variant="contained" onClick={onClickOpenCreateStoreModal} color="primary">
              Create store
            </Button>
          </div>
          <CreateStoreModal
            open={isCreateStore}
            handleClose={onCloseModalCreateStore}
            setStoreInfo={setStoreInfo}
            user_id={userInfo?.user_id}
          />
        </Fragment>
      )}
      {isOpenModalUploadVideo && (
        <UploadNewVideoModal
          open={isOpenModalUploadVideo}
          handleClose={onCloseModalUploadNewVideo}
          setValueTab={setValueTab}
        />
      )}
      <SnackbarMessage
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        setOpen={handleClose}
      />
    </div>
  )
};

UserStore.propTypes = {
  isOwner: bool.isRequired,
  setValueTab: func
};

UserStore.defaultProps = {
  setValueTab: () => {}
}

export default UserStore;
