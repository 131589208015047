import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MenuAppBar from "Shared/AppBar/AppBar";
import Footer from "Shared/Footer/Footer";

const useStyles = makeStyles(() => ({
  root: {
    margin: "0 auto",
    padding: "50px 15px 30px",
    minHeight: "800px",
  }
}))

const PageContainerWrap = ({ children, landingPage }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <MenuAppBar />
      <div className={classes.root}
        style={{
          maxWidth: landingPage ? "1000px" : "1600px"
        }}
      >
        {children}
      </div>
      <Footer />
    </Fragment>
  )
};

export default PageContainerWrap;
