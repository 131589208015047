
let cognitoConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:a39b1f52-67f3-48e9-817a-9d091429278b",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_hO7syuUDd",
  aws_user_pools_web_client_id: "1f71j2oivhape98v835ijegvr5",
  oauth: {
        domain: "https://getshopflix.auth.us-east-1.amazoncognito.com",
        clientID: "1f71j2oivhape98v835ijegvr5",
        redirectUri: "http://localhost:3000/login",
        audience: "http://localhost:3000",
        responseType: "openid email",
        scope: "openid profile email",
        returnTo: "http://localhost:3000"
    }
};


if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  cognitoConfig = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:a39b1f52-67f3-48e9-817a-9d091429278b",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_hO7syuUDd",
    aws_user_pools_web_client_id: "1f71j2oivhape98v835ijegvr5",
    oauth: {
          domain: "https://getshopflix.auth.us-east-1.amazoncognito.com",
          clientID: "1f71j2oivhape98v835ijegvr5",
          redirectUri: "https://getshopflix.com/login",
          audience: "https://getshopflix.com",
          responseType: "openid email",
          scope: "openid profile email",
          returnTo: "https://getshopflix.com"
      }
  };
}

const apiConfig = {
  Auth: {
      identityPoolId: "us-east-1:a39b1f52-67f3-48e9-817a-9d091429278b",
      region: "us-east-1",
      userPoolId: "us-east-1_hO7syuUDd",
      userPoolWebClientId: "1f71j2oivhape98v835ijegvr5",
  },
  API: {
      endpoints: [
          {
              name: "GSFDev",
              endpoint: "https://dbvmk0bgf8.execute-api.us-east-1.amazonaws.com/dev"
          },
          {
              name: "GSFProd",
              endpoint: "https://dbvmk0bgf8.execute-api.us-east-1.amazonaws.com/prod"
          }
      ]
  }
}

const urls = {
  dev : "http://dev.getshopflix.com.s3-website-us-east-1.amazonaws.com",
  prod: "https://getshopflix.com"
}


let apiName = 'GSFDev';
let baseUrl = urls.dev;

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  apiName = 'GSFProd';
  baseUrl = urls.prod;
}


console.log(process.env.REACT_APP_ENVIRONMENT);

export { cognitoConfig, apiConfig, apiName, baseUrl };
