import { makeStyles } from "@material-ui/core/styles";
// import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { Grid, Paper, ListItem, ListItemText, Link } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";

// import useRouter from "utils/hooks/useRouter";
import { color } from "utils/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    padding: theme.spacing(2),
    backgroundColor: color.primary,
    color: "#333",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  footerContainer: {
    maxWidth: 960,
    margin: "0 auto"
  },
  columnTitleFooter: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
    letterSpacing: 0.1,

    "& span": {
      marginLeft: theme.spacing(1)
    }
  },
  listMenuWrap: {
    padding: 0,
  },
  copyRightAndSocialFooter: {
    paddingTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  copyRightText: {
  },
  socialLink: {
    display: "flex",
    alignItems: "center",
    listStyle: "none"
  },
  socialIcon: {
    cursor: "pointer",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(1)
    }
  },
  icon: {
    fontSize: 28,
    transition: "color .2s ease-out"
  },
  facebookIcon: {
    "&:hover": {
      color: "#3b5999"
    }
  },
  twitterIcon: {
    "&:hover": {
      color: "#55acee"
    }
  }
}))

const Footer = () => {
	const classes = useStyles();

	return (
		<Paper className={classes.root}>
      <div className={classes.footerContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} xl={3}>
            <div className={classes.columnTitleFooter}>
              <InfoOutlinedIcon /> <span>About Us</span>
            </div>
            <ListItem className={classes.listMenuWrap}>
              <ListItemText>
                <Link color="inherit" href="/about-us">
                  About Us
                </Link>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <div className={classes.columnTitleFooter}>
              <EmailOutlinedIcon /> <span>Contact Us</span>
            </div>
            <ListItem className={classes.listMenuWrap}>
              <ListItemText>
                <Link color="inherit" href="/contact-us">
                  Contact Us
                </Link>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <div className={classes.columnTitleFooter}>
              <DescriptionOutlinedIcon /> <span>Terms of Service</span>
            </div>
            <ListItem className={classes.listMenuWrap}>
              <ListItemText>
                <Link color="inherit" target="_blank" href="/terms-of-service.html">
                  Terms of Service
                </Link>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <div className={classes.columnTitleFooter}>
              <DescriptionOutlinedIcon /> <span>Privacy Policy</span>
            </div>
            <ListItem className={classes.listMenuWrap}>
              <ListItemText>
                <Link color="inherit" target="_blank" href="/privacy-policy.html">
                  Privacy Policy
                </Link>
              </ListItemText>
            </ListItem>
          </Grid>
        </Grid>
        <div className={classes.copyRightAndSocialFooter}>
          <div className={classes.copyRightText}>
            © 2021 GetShopflix
          </div>
          <ul className={classes.socialLink}>
            <li className={classes.socialIcon}>
              <Link color="inherit" target="_blank" href="https://www.facebook.com/getshopflix">
                <FacebookIcon className={`${classes.facebookIcon} ${classes.icon}`} />
              </Link>
            </li>
            <li className={classes.socialIcon}>
              <Link color="inherit" target="_blank" href="https://twitter.com/getshopflix">
                <TwitterIcon className={`${classes.twitterIcon} ${classes.icon}`} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
		</Paper>
		);
};

export default Footer;
