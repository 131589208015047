import React, { Fragment, useState, useEffect } from "react";
import { bool, object } from "prop-types";
import { makeStyles } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SendIcon from "@material-ui/icons/Send";
import ReactGA from 'react-ga';

import api from "utils/api";
import { color } from "utils/styles";

const useStyles = makeStyles(() => ({
  reactionIconWrap: {
    marginBottom: 8,
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "rgba(0, 0, 0, 0.2)",
    position: "relative",
    cursor: "pointer",
    display: "grid",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "&:hover": {
      transform: "scale(1.1)"
    },
  },
  iconReaction: {
    fontSize: 22
  },
  buttonGotoShop: {
    backgroundColor: color.reaction,
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: color.white,
    textAlign: "center",
    lineHeight: "40px",
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 12,
    position: "transform .5s ease-in",

    "&:hover": {
      transform: "scale(1.1)"
    },

    "& a": {
      color: color.white,
      textDecoration: "none"
    }
  },
  reactionCount: {
    color: color.white,
    fontSize: 10,
    marginTop: 1,
    marginBottom: 0
  }
}));

const IconVideoKit = ({
  isDisplayShopButton, videoInfo
}) => {
  const classes = useStyles();
  const [isLiked, setIsLiked] = useState();
  const [likeCount, setLikeCount] = useState();


  const onLikedVideo = async (action, cancelled) => {
    if (!cancelled) {
      setIsLiked(true)
      setLikeCount(likeCount + 1)
    }
    const form = {
      action: action,
      cancelled: cancelled
    }
    try {
      const response = await api.post(`/videos/${videoInfo?.video_id}/stats`, form);
      ReactGA.event({
        category: 'Button',
        action: 'Like video'
      });
    } catch (e) {
      console.log("e", e);
    }
  }

  const onShareVideo = async (action) => {
    const form = {
      action: action
    }
    try {
      const response = await api.post(`/videos/${videoInfo?.video_id}/stats`, form);
    } catch (e) {
      console.log("e", e);
    }
  }

  const onStoreVisit = async (action, link) => {
    window.open(link, "_blank").focus();
    const form = {
      action: action
    }
    try {
      const response = await api.post(`/videos/${videoInfo?.video_id}/stats`, form);
      ReactGA.event({
        category: 'Button',
        action: 'Visit product',
        label: link
      });
    } catch (e) {
      console.log("e", e);
    }

  }

  useEffect(() => {
    setLikeCount(videoInfo?.likes_received)
  }, [videoInfo]);

  return (
    <Fragment>
      <div
        className={classes.reactionIconWrap}
        onClick={() => isLiked ? (
          onLikedVideo("like", false)
        ) : (
          onLikedVideo("like", false)
        )}
      >
        <FavoriteIcon
          style={{ color: (videoInfo.isLiked || isLiked )? color.reaction : color.white }}
          className={classes.iconReaction}
        />
        <div className={classes.reactionCount}>
          {likeCount}
        </div>
      </div>
      {isDisplayShopButton && (
        <div className={classes.buttonGotoShop}
          onClick={() => onStoreVisit("visit", videoInfo.link)}>
            Shop
        </div>
      )}
    </Fragment>
  )
};

IconVideoKit.propTypes = {
  isDisplayShopButton: bool,
  videoInfo: object
};

IconVideoKit.defaultProps = {
  isDisplayShopButton: true,
  videoInfo: {}
}

export default IconVideoKit;
