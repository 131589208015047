import { useState, Fragment, useEffect } from "react";
import {
  makeStyles,
  Typography,
  FormGroup,
  ButtonGroup,
  Button,
  IconButton,
  TextField,
  Switch
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import { isEmpty } from "lodash";

import ModalComponent from "components/ModalComponent/ModalComponent";
import SnackbarMessage from "Shared/Snackbar/Snackbar";
import Loading from "Shared/Loading/Loading";
import { color } from "utils/styles";
import { renderErrors } from "utils/function";
import api from "utils/api";

const useStyles = makeStyles((theme) => ({
  createStoreModal: {
    minWidth: "500px",
    [theme.breakpoints.down('md')]: {
      minWidth: "300px",
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: "250px",
    },
  },
  formGroup: {
    marginTop: 32,
  },
  formGroupSwitch: {
    marginTop: 32,
    display: "block",
  },
  buttonGroup: {
    marginTop: 32,
  },
  uploadButton: {
    backgroundColor: color.primary,
    height: 48,
  },
  submitButton: {
    borderRightColor: "unset !important",
  },
  modalTitleBlock: {
    display: "flex",
  },
  modalTitle: {
    width: "100%",
  },
  closeButton: {
    color: color.primary,
    padding: "0px 12px",
  },
  resetButton: {
    marginLeft: "16px !important",
    backgroundColor: color.errors,
    color: color.white,

    "&:hover": {
      color: color.black,
    },
  },
  fileInfoText: {
    marginTop: theme.spacing(1)
  }
}));

const UploadNewVideoModal = (props) => {
  const {open, handleClose, ...other } = props;

  const classes = useStyles();
  const userInfo = useSelector(state => state?.authentication?.userInfo);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileSelectedMessage, setFileSelectedMessage] = useState("select video file");
  const [showClose, setShowClose] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: ""
  });

  const { handleSubmit, control, reset, setError, formState: { errors }, clearErrors } = useForm();

  const showUploadResult = (result) => {
    if (result.status === 200) {
      setOpenSnackbar({
        open: true,
        message: "Upload video success! Video will appear in your profile page once porcessing is complete.",
        type: "success"
      });
    } else {
      setOpenSnackbar({
        open: true,
        message: "Upload video failed. Please try again.",
        type: "error"
      });
    }
  }

  const onSubmit = async (data) => {
    if (!fileUpload) {
      await setOpenSnackbar({
        open: true,
        message: "no file selected.",
        type: "error"
      });
      return;
    }
    setShowClose(false);
    setIsLoading(true);
    await setOpenSnackbar({
      open: true,
      message: "Uploading video... Please wait.",
      type: "info"
    });
    data.status =  data.status ? "live" : "draft";
    if (!fileUpload?.name) {
      setIsLoading(false);
      return setError("file_name", {
        type: "manual",
        message: "Please select video!"
      })
    }
    const form = {
      user_id: userInfo?.user_id,
      type: fileUpload?.type,
      file_name: fileUpload?.name,
      ...data,
    }

    try {
      const response = await api.post("/videos", form);
      const { signed_url } = response;

      try {
        const responsePresignURL = await axios.put(signed_url, fileUpload, {
          headers: {
            "Content-Type": fileUpload.type
          }
        });
        await showUploadResult(responsePresignURL);

      } catch (e) {
        console.log("e", e);
      }
    } catch (e) {
      console.log("e", e);
    }
    setShowClose(true);
    setIsLoading(false);
    reset();
  };

  useEffect(() => {
    if (!isEmpty(fileUpload?.name)) {
      clearErrors("file_name")
    }
  }, [fileUpload, clearErrors]);

  const onReset = () => {
    setFileUpload(null);
    reset();
    setFileSelectedMessage("select video file")
  }

  const onChangeFile = (e) => {
    setFileUpload(e.target.files[0]);
    setFileSelectedMessage(`selected video: ${e.target.files[0].name}` )
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar({
      open: false,
      message: ""
    });
  }

  return (
    <Fragment>
    <ModalComponent {...props}>
      <div className={classes.createStoreModal}>
        <div className={classes.modalTitleBlock}>
        <Typography
          className={classes.modalTitle}
          variant="h6"
          component="h6"
          gutterBottom
        >
          Upload a new video
        </Typography>
        { showClose ? (<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton> ): null}

        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className={classes.formGroup}>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <TextField
                  autoComplete="off"
                  autoFocus
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  label="Video title"
                  defaultValue=""
                  name={name}
                  innerRef={ref}
                />
              )}
              control={control}
              name="video_title"
            />
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <TextField
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  label="Video description"
                  defaultValue=""
                  name={name}
                  innerRef={ref}
                />
              )}
              control={control}
              name="video_description"
            />
          </FormGroup>
          <FormGroup className={classes.formGroupSwitch} row>
            <span> draft</span>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <Switch
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  name={name}
                  innerRef={ref}
                />
              )}
              control={control}
              name="status"
            />
            <span> live</span>
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Controller
              render={({
                field: { onChange, onBlur, name, ref },
              }) => (
                <TextField
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  label="Product Link"
                  defaultValue=""
                  name={name}
                  innerRef={ref}
                />
              )}
              control={control}
              name="link"
            />
          </FormGroup>
            <FormGroup className={classes.formGroup}>
            <Button variant="outlined" component="label" className={classes.uploadButton}>
              {fileSelectedMessage}
              <input
                type="file"
                hidden
                onChange={onChangeFile} accept="video/mp4,video/x-m4v,video/*"
              />
              </Button>
              </FormGroup>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              variant="contained"
              className={classes.submitButton}
              color="primary"
              type="submit"
            >
              Submit
            </Button>
            <Button
              className={classes.resetButton}
              onClick={onReset}
            >
              Reset
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </ModalComponent>
    <SnackbarMessage
      open={openSnackbar.open}
      type={openSnackbar.type}
      message={openSnackbar.message}
      setOpen={handleSnackbarClose}
    />
    {isLoading && <Loading />}
    </Fragment>
  );
};

export default UploadNewVideoModal;
