import { makeStyles } from "@material-ui/core";

export const color = {
  primary: "#5efdcc",
  secondary: "#fdce5e",
  white: "#ffffff",
  black: "#000000",
  errors: "#dc3545",
  reaction: "#f9455e",
  redHover: "#d32f2f"
}

export const mixin = {
  linkStyle: {
    textDecoration: "none",
    color: color.black,

    "&:hover": {
      textDecoration: "underline"
    }
  },
  resetButton: {
    marginLeft: "16px !important",
    backgroundColor: color.errors,
    color: color.white,

    "&:hover": {
      color: color.black,
    },
  },
}

export const useStylesForm = makeStyles((theme) => ({
  formSubmit: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    width: "50%"
  },
  formControl: {
    margin: theme.spacing(2)
  },
  formGroupSwitch: {
    margin: theme.spacing(2),
    display: "block",
  },
  resetButton: {
    marginLeft: "12px !important"
  }
}))
