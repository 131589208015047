import React from 'react';
import { Redirect } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useDispatch } from "react-redux";
import { checkIsAuthen, setIdentityId, setUserProfile, setUserInfo } from "features/authentication/authenticationSlice";
import api from "utils/api";

const Login = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const dispatch = useDispatch();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState === AuthState.SignedIn) {
              let identityId;
              Auth.currentUserCredentials().then(async(creds) => {
                  identityId = creds.identityId.replace("us-east-1:", "");
                  const data = {
                    user_id: identityId,
                    user_name: authData.attributes.name,
                    email: authData.attributes.email,
                  }

                  try {
                    const response = await api.post("/users", data);
                  } catch (e) {
                    console.log("e", e);
                  }

                  dispatch(setIdentityId(identityId));
                  dispatch(checkIsAuthen(true));
                  dispatch(setUserProfile(creds.sessionToken))

              }).catch(e => {
                  console.log(e)
                  dispatch(checkIsAuthen(false));
                  dispatch(setIdentityId({}));
                  dispatch(setUserInfo({}));
                  dispatch(setUserProfile({}));
              });

            }

            setAuthState(nextAuthState);
            setUser(authData)
        });



    }, [dispatch]);
  return authState === AuthState.SignedIn && user ? (
      <Redirect to={{ pathname: "/" }}/>
    ) : (
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "name",
              label: "Username",
              placeholder: "Your username",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "email",
              label: "Email",
              placeholder: "Your email address",
              inputProps: { required: true, autocomplete: "email" },
            },
            {
              type: "password",
              label: "Password",
              placeholder: "Your account password",
              inputProps: { required: true, autocomplete: "new-password" },
            }
          ]}
        />
      <AmplifySignIn slot="sign-in" usernameAlias="email">
        <div slot="federated-buttons"></div>
      </AmplifySignIn>
      </AmplifyAuthenticator>
  );
}

export default Login;
