import { Route, Switch } from "react-router-dom";
import Login from "components/Login/Login";
import Home from "pages/Home";
import Profile from "pages/Profile";
import Protected from "./Protected";
import VideoDetail from "pages/video/VideoDetail";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import UserAgreement from "pages/TermsOfService";

const AppWithRouterAccess = () => {

  return (
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/protected" component={Protected} />
        <Route path="/profile/:userId" component={Profile} />
        <Route path="/login" render={() => <Login />} />
        <Route path="/video/:id" component={VideoDetail} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/terms-of-service" component={UserAgreement} />
      </Switch>
  );
};
export default AppWithRouterAccess;
