import { Fragment, useState, useEffect, useCallback, setState } from "react";
import { func, string, bool, number } from "prop-types";
import { makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import get from "lodash/get";
import Alert from "@material-ui/lab/Alert";
import Masonry from "react-masonry-css";

import SingleVideoComponent from "Shared/SingleVideoComponent/SingleVideoComponent";
import UploadNewVideoModal from "components/UploadNewVideoModal/UploadNewVideoModal";

import api from "utils/api";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


const useStyles = makeStyles(() => ({
  mansory: {
    display: "flex",
    marginLeft: "-20px",
    width: "auto",
  },
  mansoryItem: {
    paddingLeft: "20px",
    backgroundClip: "padding-box",
  },
  buttonOpenCreataStoreModal: {
    marginTop: 16,
    marginBottom: 16
  }
}))

const ListVideos = ({
  setValueTab, queryStringVideos, isOwner, valueTab
}) => {
  const classes = useStyles();
  const [isOpenModalUploadVideo, setIsOpenModalUploadVideo] = useState(false);

  const [videos, setVideos] = useState([]);
  const [isProfileOwner, setIsProfileOwner] = useState(false);

  const userInfo = useSelector(state => state.authentication.userInfo);
  const profileUserInfo = useSelector(state => state.authentication.profileUserInfo);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    900: 2,
    500: 1
  };

  const onClickOpenUploadNewVideoModal = () => {
    if (userInfo?.store_id) {
      setIsOpenModalUploadVideo(true);
    } else {
      setValueTab(2);
    }
  }

  const onCloseModalUploadNewVideo = () => {
    setIsOpenModalUploadVideo(false)
  }

  const getVideoByUserId = useCallback(async () => {
    try {
      const response = await api.get(`/videos?userId=${profileUserInfo?.user_id}${queryStringVideos}`);
      setVideos(get(response, "videos", []));
    } catch (e) {
      console.log("e", e);
    }
  }, [queryStringVideos, profileUserInfo?.user_id]);

  useEffect(() => {
    setIsProfileOwner(isOwner);
  }, [isOwner]);

  useEffect(() => {
    getVideoByUserId();
  }, [getVideoByUserId]);

  useEffect(() => {
    const tabName = valueTab === 0 ? "video" : "favorite"
    ReactGA.pageview(window.location.pathname + "-" + tabName)
    ReactPixel.pageView();
  }, [])

  return (
    <Fragment>
      {(isProfileOwner && valueTab === 0) ? (
        <Button className={classes.buttonOpenCreataStoreModal} variant="contained" onClick={onClickOpenUploadNewVideoModal} color="primary">
        Upload new video
      </Button>
    ) : (null)}

      {videos.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={classes.mansory}
          columnClassName={classes.mansoryItem}
          >
          {videos.map(video => (
            <SingleVideoComponent
              key={video.video_id}
              videoId={video.video_id}
              video={video}
            />
          ))}
        </Masonry>
      ) : (isProfileOwner && valueTab === 0) ? (
        <Alert severity="info">You dont have video yet. Click button to create!</Alert>
      ) : (null)}
      <div>
      {isOpenModalUploadVideo && (
        <UploadNewVideoModal open={isOpenModalUploadVideo} handleClose={onCloseModalUploadNewVideo} />
      )}
      </div>
    </Fragment>
  )
};

ListVideos.propTypes = {
  setValueTab: func,
  queryStringVideos: string.isRequired,
  isOwner: bool.isRequired,
  valueTab: number
};

ListVideos.defaultProps = {
  setValueTab: () => {},
  valueTab: undefined
};

export default ListVideos;