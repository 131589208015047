import { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { color } from "utils/styles";

import { makeStyles,IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ModalComponent from "components/ModalComponent/ModalComponent";

import popup from "assets/img/pop-up.png";

const useStyles = makeStyles((theme) => ({
  promptSignUpModal: {
    minWidth: "500px",
    [theme.breakpoints.down('md')]: {
      minWidth: "300px",
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: "300px",
    },
  },
  closeButton: {
    color: color.primary,
    padding: "0px 12px",
    float: "right",
    marginTop: "-10px",
    marginRight: "-32px",
  },
  popupImg: {
    width: "100%",
  }
}));

const PromptToSignUpModal = (props) => {
  const {open, handleClose, ...other } = props;

  const classes = useStyles();
  
  return (
    <Fragment>
    <ModalComponent {...props}>
      <div className={classes.promptSignUpModal}>
        <div>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton> 
          <Link to="/login">
              <img className={classes.popupImg} src={popup} alt="popup" />
          </Link>
        </div>
      </div>
    </ModalComponent>
    </Fragment>
  );
};

export default PromptToSignUpModal;
