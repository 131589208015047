import { Fragment, useState } from "react";
import { object, func } from "prop-types";
import {
  makeStyles,
  TextField,
  FormControl,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { mixin } from "utils/styles";

import Loading from "Shared/Loading/Loading";
import api from "utils/api";
import { renderErrors } from "utils/function";

const useStyles = makeStyles((theme) => ({
  formSubmit: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    width: "80%",
    flex: "0 0 100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: theme.spacing(0),
    },
  },
  formControl: {
    margin: theme.spacing(2),
  },
  formGroupSwitch: {
    margin: theme.spacing(2),
    display: "block",
  },
  resetButton: {
    marginLeft: "12px !important",
  },
}));

const UpdateStoreForm = ({
  userInfo,
  onEditStore,
  updatedField,
  setStoreInfo,
  setOpenSnackbar
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: updatedField,
  });
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await api.put(
        `/users/${userInfo?.user_id}/stores/${updatedField?.store_id}`,
        data
      );
      await setOpenSnackbar({
        open: true,
        message: "Update store success!",
        type: "success",
      });
      setStoreInfo(response);
      await onEditStore();
    } catch (e) {
      setOpenSnackbar({
        open: true,
        message: "Update store failed!",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <form className={classes.formSubmit} onSubmit={handleSubmit(onSubmit)}>
        <FormControl className={classes.formControl}>
          <Controller
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                autoComplete="off"
                autoFocus
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                label="Store Name"
                defaultValue={value}
                name={name}
                innerRef={ref}
                error={!isEmpty(errors?.store_name)}
                helperText={renderErrors(errors?.store_name?.type)}
              />
            )}
            rules={{
              required: true,
            }}
            control={control}
            name="store_name"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Controller
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                autoComplete="off"
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                label="Store URL"
                defaultValue={value}
                name={name}
                innerRef={ref}
                error={!isEmpty(errors?.store_url)}
                helperText={renderErrors(errors?.store_url?.type)}
              />
            )}
            rules={{
              required: true,
            }}
            control={control}
            name="store_url"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Controller
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                autoComplete="off"
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                label="Store Description"
                defaultValue={value}
                name={name}
                innerRef={ref}
                error={!isEmpty(errors?.store_description)}
                helperText={renderErrors(errors?.store_description?.type)}
              />
            )}
            rules={{
              required: true,
            }}
            control={control}
            name="store_description"
          />
        </FormControl>
        <ButtonGroup className={classes.buttonGroup}>
          <Button
            variant="contained"
            className={classes.submitButton}
            color="primary"
            type="submit"
          >
            Submit
          </Button>
          <Button
            className={classes.resetButton}
            style={mixin.resetButton}
            onClick={onEditStore}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </form>
      {isLoading && <Loading />}
    </Fragment>
  );
};

UpdateStoreForm.propTypes = {
  userInfo: object.isRequired,
  onEditStore: func,
  updatedField: object,
  setStoreInfo: func,
  setOpenSnackbar: func
};

UpdateStoreForm.defaultProps = {
  onEditStore: () => {},
  updatedField: {},
  setStoreInfo: () => {},
  setOpenSnackbar: () => {}
};

export default UpdateStoreForm;
