import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import get from "lodash/get";
import Masonry from "react-masonry-css";

import { Auth } from 'aws-amplify';
import { checkIsAuthen, setIdentityId, setUserProfile, setUserInfo } from "features/authentication/authenticationSlice";
import SingleVideoComponent from "Shared/SingleVideoComponent/SingleVideoComponent";
import PageContainerWrap from "components/PageContainerWrap/PageContainerWrap";
import PromptToSignUpModal from "components/PromptToSignUpModal/PromptToSignUpModal";


import api from "utils/api";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


const useStyles = makeStyles(() => ({
  imageContainerWrap: {
    padding: "1.5em",
    maxWidth: "1440px",
    margin: "0 auto",
  },
  mansory: {
    display: "flex",
    marginLeft: "-20px",
    width: "auto",
  },
  mansoryItem: {
    paddingLeft: "20px",
    backgroundClip: "padding-box",
  },
}));



export default function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [isOpenModalSignUp, setIsOpenModalSignUp] = useState(false);
  const [shouldOpenModalSignUp, setShouldOpenModalSignUp] = useState(false);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    900: 2,
    500: 1
  };


  const onCloseModalModalSignUp = () => {
    setIsOpenModalSignUp(false);
    setShouldOpenModalSignUp(false);
  }
  
  useEffect(() => {
    (async () => {
      Auth.currentUserCredentials().then(async(creds) => {
          if (creds.authenticated) {
            const identityId = creds.identityId.replace("us-east-1:", "");
            dispatch(setIdentityId(identityId));
            dispatch(checkIsAuthen(true));
            dispatch(setUserProfile(creds.sessionToken));

          } else {
            dispatch(checkIsAuthen(false));
            dispatch(setIdentityId({}));
            dispatch(setUserInfo({}));
            dispatch(setUserProfile({}));
            setShouldOpenModalSignUp(true);
          }
      }).catch(e => {
          console.log(e)
      });

      const response = await api.get("/videos");
      setVideos(get(response, "videos", []));
    })()
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView();
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpenModalSignUp(true)
    }, 15000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <PageContainerWrap>
      <div>
      {shouldOpenModalSignUp && (
        <PromptToSignUpModal open={isOpenModalSignUp} handleClose={onCloseModalModalSignUp}/>
      )}
      </div>
      <div className={classes.imageContainerWrap}>
        <Masonry
        breakpointCols={breakpointColumnsObj}
        className={classes.mansory}
        columnClassName={classes.mansoryItem}
        >
          {videos.map(video => (
            <SingleVideoComponent
              key={video.video_id}
              video={video}
            />
          ))}
        </Masonry>
      </div>
    </PageContainerWrap>
  )
}
