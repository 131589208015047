import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
    identityId: null,
    userProfile: null,
    userInfo: null,
    profileUserInfo: null,
}

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    checkIsAuthen: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setIdentityId: (state, action) => {
      state.identityId = action.payload
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setProfileUserInfo: (state, action) => {
      state.profileUserInfo = action.payload
    },
    onLogout: () => {
      localStorage.removeItem("persist:root");
      return initialState
    }
  }
});

export const { checkIsAuthen, setIdentityId, setUserProfile, setUserInfo, setProfileUserInfo, onLogout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
