import { apiConfig, apiName } from "./config";
import Amplify, { API } from 'aws-amplify';

Amplify.configure(apiConfig);
//Amplify.Logger.LOG_LEVEL = 'DEBUG'


const apiGet = (url) =>
  new Promise((resolve, reject) => {
    const init = {
      headers: {
        'Content-Type': "application/json"
      },
    };
    API.get(apiName, url, init)
    .then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
    );
  });

const apiPost = (url, variables) =>
  new Promise((resolve, reject) => {
    const init = {
      headers: {
        'Content-Type': "application/json"
      },
      body: variables,
    };
    API.post(apiName, url, init)
    .then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
    );

  });

const apiPut = (url, variables) =>
  new Promise((resolve, reject) => {
    const init = {
      headers: {
        'Content-Type': "application/json"
      },
      body: variables,
    };
    API.put(apiName, url, init)
    .then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
    );

  });

const optimisticUpdate = async (url, { updatedFields, currentFields, setLocalData }) => {
  try {
    setLocalData(updatedFields);
    await apiPut(url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
  }
};

export default {
  get: (...args) => apiGet(...args),
  post: (...args) => apiPost(...args),
  put: (...args) => apiPut(...args),
  optimisticUpdate,
};
