import { makeStyles } from "@material-ui/core";
import PageContainerWrap from "components/PageContainerWrap/PageContainerWrap";

const TermsOfService = () => {

  return (
    <PageContainerWrap>
      this is Terms Of Service page
    </PageContainerWrap>
    )
};

export default TermsOfService;
