import "./Loading.scss";

const Loading = () => (
  <div className="overlay">
    <div className="lds-facebook">
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loading;
