import React, { useState, useEffect } from "react";
import { Card, CardContent, makeStyles, Typography, Link } from "@material-ui/core";
import get from "lodash/get";

import VideoPlayer from "Shared/VideoPlayer/VideoPlayer";
import { mixin, color } from "utils/styles";

const useStyles = makeStyles(() => ({
  singleVideoRoot: {
    marginBottom: 20,
  },
  videoTitle: {
    marginTop: 10,
    fontWeight: "bold",
    fontSize: "1.2rem",
    marginLeft: 4,
    ...mixin.linkStyle,
    "&:hover": {
      textDecoration: "none",
      color: color.primary,
    }
  },
  linkStoreStyles: {
    fontSize: "1rem",
    lineHeight: 1.4,
    textDecoration: "none",
    marginLeft: 4,
    color: "#333",
    cursor: "pointer",
    "&:hover": {
      color: color.primary,
      textDecoration: "none",
    }
  }
}))

const SingleVideoComponent = ({
  video
}) => {
  const classes = useStyles();
  const [videoInfo, setVideoInfo] = useState();

  useEffect(() => {
    setVideoInfo(video)
  }, [video]);

  return (
    <Card className={classes.singleVideoRoot} >
      <CardContent>
          <VideoPlayer videoInfo={videoInfo} />
          <Link underline="none" href={`/video/${video?.video_id}`}>
            <Typography className={classes.videoTitle} variant="h6" component="h6">
              {get(videoInfo, "video_title", "")}
              {(videoInfo?.status && videoInfo?.status !== 'live') ? (" 📝") : ("")}
            </Typography>
          </Link>
          <Link underline="none" href={`profile/${videoInfo?.video_user_id}`}>
            <Typography className={classes.linkStoreStyles} >
              {get(videoInfo, "store_name", "")}
            </Typography>
          </Link>
      </CardContent>
    </Card>
  )
};

export default SingleVideoComponent;
