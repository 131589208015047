import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core";

import PageContainerWrap from "components/PageContainerWrap/PageContainerWrap";
import VideoPlayer from "Shared/VideoPlayer/VideoPlayer";
import VideoInfo from "components/VideoDetailPage/VideoInfo";
import Loading from "Shared/Loading/Loading";
import VideoEdit from "components/VideoDetailPage/VideoEdit";

import api from "utils/api";
import useRouter from "utils/hooks/useRouter";

const useStyles = makeStyles((theme) => ({
  videoDetailPageWrap: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "grid",
    },
  },
}));

const VideoDetail = () => {
  const [video, setVideo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditVideo, setIsEditVideo] = useState(false);
  const classes = useStyles();
  const router = useRouter();

  const getVideoDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/videos/${router.query?.id}`);
      setVideo(response);
    } catch (e) {
      console.log("e", e);
    }
    setIsLoading(false);
  }, [router.query?.id]);

  useEffect(() => {
    getVideoDetail();
  }, [getVideoDetail]);

  const onSwitchEditVideoScreen = () => {
    setIsEditVideo(true);
  };

  const onEditVideo = () => {
    setIsEditVideo(isEditVideo => !isEditVideo);
  };

  return (
    <PageContainerWrap>
      {isLoading && <Loading />}
      <div className={classes.videoDetailPageWrap}>
        <VideoPlayer videoInfo={video} />
        {isEditVideo ? (
          <VideoEdit
            updatedField={{
              video_id: video?.video_id,
              video_title: video?.video_title,
              video_description: video?.video_description,
              status: video?.status,
              link: video?.link,
            }}
            onEditVideo={onEditVideo}
            setVideo={setVideo}
          />
        ) : (
          <VideoInfo
            video={video}
            onSwitchEditVideoScreen={onSwitchEditVideoScreen}
          />
        )}
      </div>
    </PageContainerWrap>
  );
};

export default VideoDetail;
