import { bool, func, node } from "prop-types";
import { Modal, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalComponentWrap: {
    padding: theme.spacing(2, 4, 6),
    minHeight: 500,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "40%",
    left: "50%",
    transform: `translate(-${50}%, -${50}%)`,
    position: "absolute"
  },
}));

const ModalComponent = ({
  open, handleClose, children
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modalComponentWrap}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-component"
      disableBackdropClick
    >
      <div className={classes.paper}>
        {children}
      </div>
    </Modal>
  )
};

ModalComponent.propTypes = {
  open: bool,
  handleClose: func,
  children: node
};

ModalComponent.defaultProps = {
  open: false,
  handleClose: () => {},
}

export default ModalComponent;
