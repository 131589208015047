import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Amplify } from 'aws-amplify';
import AppWithRouterAccess from "./AppWithRouterAccess";
import theme from "./theme";
import { store, persistor } from "./stores";
import { cognitoConfig } from "utils/config";

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

Amplify.configure(cognitoConfig);

ReactGA.initialize('UA-210321701-1');

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
ReactPixel.init('956095564974071', advancedMatching, options);


const App = () => (
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppWithRouterAccess/>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </Router>
);
export default App;
