import { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/Button";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Amplify, Auth } from "aws-amplify";
import {
  checkIsAuthen,
  setIdentityId,
  setUserProfile,
  setUserInfo,
  onLogout
} from "features/authentication/authenticationSlice";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { cognitoConfig } from "utils/config";

import useRouter from "utils/hooks/useRouter";
import banner from "assets/img/banner-white.png";

Amplify.configure(cognitoConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  siteName: {
    textDecoration: "none",
    "&:a(:visited)": {
      color: "red",
      textDecoration: "none",
    },
  },
  siteBanner: {
    height: 110,
    position: "absolute",
    top: 0,
    left: 50,
    [theme.breakpoints.down('md')]: {
      height: 110,
      left: 30,
    },
    [theme.breakpoints.down('sm')]: {
      height: 80,
      left: 10,
    },
  },
  userAccount: {
    fontSize: 34,
  },
}));

const MenuAppBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const router = useRouter();

  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const identityId = useSelector((state) => state.authentication.identityId);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoProfilePage = () => {
    router.push(`/profile/${identityId}`);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({ global: true });
      dispatch(checkIsAuthen(false));
      dispatch(setIdentityId({}));
      dispatch(setUserInfo({}));
      dispatch(setUserProfile({}));
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const onLogin = () => {
    router.push("/login");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.siteName} to="/">
              <img className={classes.siteBanner} src={banner} alt="logo" />
            </Link>
          </Typography>
          {isAuthenticated ? (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle className={classes.userAccount} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={gotoProfilePage}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle className={classes.userAccount} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={onLogin}>Login</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MenuAppBar;
