import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { makeStyles, Card, Avatar, Typography, IconButton } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Tooltip from "@material-ui/core/Tooltip";

import get from "lodash/get";
import SnackbarMessage from "Shared/Snackbar/Snackbar";
import Loading from "Shared/Loading/Loading";
import api from "utils/api";
import { color } from "utils/styles";
import { baseUrl } from "utils/config";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: 32,
  },
  form: {
    width: "100% !important"
  },
  root: {
    color: color.primary,
    height: "80vh",
    width: "240px",
    padding: "20px 10px",
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      width: "140px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "0px",
      padding: "0px",
    },
  },
  largeAvatar: {
    position: "relative",
    width: theme.spacing(18),
    height: theme.spacing(18),
    margin: "10px auto",
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  userAvatar: {
    width: "100%",
    opacity: 1,
    display: "block",
    height: "auto",
    transition: ".5s ease",
    backfaceVisibility: "hidden",
    position: "relative",

    "&:hover img": {
      opacity: 0.3
    },

    "&:hover .middle": {
      opacity: 1,
      transition: ".5s ease",
    }
  },
  labelIconUpload: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
    position: "absolute",
    opacity: 0,
    zIndex: 200,
  },
  username: {
    textAlign: "center",
    fontSize: "1.4rem",
    margin: "20px 0"
  },
  storeProfile: {
    marginTop: theme.spacing(2)
  }
}));

const UserInfoBar = () => {
  const classes = useStyles();
  const userInfo = useSelector(state => state.authentication.userInfo);
  const userImage = `${baseUrl}/picture/user/${userInfo?.user_id}/large.jpg`
  const [userAvatar, setUserAvatar] = useState(userInfo?.user_id ? userImage : `${baseUrl}/picture/user/large.jpg`);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: ""
  });

  const showUploadResult = (result) => {
    if (result.status === 200) {
      setOpenSnackbar({
        open: true,
        message: "Upload picture success! picture will appear in your profile page once porcessing is complete.",
        type: "success"
      });
    } else {
      setOpenSnackbar({
        open: true,
        message: "Upload picture failed. Please try again.",
        type: "error"
      });
    }
  }

  const onChangeImageUser = async (e) => {
    const files = e.target.files[0];
    const userId = userInfo?.user_id

    if (userId) {
      setIsLoading(true);
      await setOpenSnackbar({
        open: true,
        message: "Uploading picture... Please wait.",
        type: "info"
      });
      try {
        const response = await api.get(`/users/${userId}/picture-signed?file_format=${files.type}`);
        const { signed_url } = response;
        if (signed_url) {
          const responsePresignURL = await axios.put(signed_url, files, {
            headers: {
              "Content-Type": files.type
            }
          });
          await showUploadResult(responsePresignURL);
          setUserAvatar(`${baseUrl}/picture/user/${userInfo?.user_id}/large.jpg`);
        }
      } catch (e) {
        console.log("e", e);
      }
      setIsLoading(false);
    }

  }

  return (
    <Card className={classes.root}>
      <Typography variant="h5">
        Hello!
      </Typography>
      <form className={classes.form}>
        <div className={classes.userAvatar}>
          <Avatar alt="getshopflix User Picture" src={userAvatar} className={`${classes.largeAvatar} picture`} />
          <span className={`${classes.labelIconUpload} middle`}>
            <input
              accept="image/*"
              id="icon-button-file-user-avatar"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeImageUser}
            />
            <label htmlFor="icon-button-file-user-avatar">
              <Tooltip title="Upload new picture">
                <IconButton aria-label="upload picture" component="span">
                  <PhotoCameraIcon />
                </IconButton>
              </Tooltip>
            </label>
          </span>
        </div>
        <div className={classes.username}>
          {get(userInfo, "user_name", "")}
        </div>
      </form>
    </Card>
  )
};

export default UserInfoBar;
